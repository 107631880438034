<template>
  <div class="mt-5">
    <div class="text-center" v-if="mspForsageOrder.group_status === 'approved' || mspForsageOrder.group_status === 'crm'">
      <img src="/pic/accelerator/fireworks.svg" />
      <h3 class="mt-2">Поздравляем</h3>
      <p>Ваша компания включена в учебный поток по программе!<br/>
        Сотрудники Центра поддержки экспорта свяжутся с Вами, чтобы рассказать о деталях обучения.</p>
    </div>
    <div class="text-center" v-else-if="mspForsageOrder.status === 'send'">
      <img src="/pic/accelerator/send.svg" />
      <h3 class="mt-2">Ваша заявка отправлена</h3>
      <p>На указанный Вами адрес электронной почты придет письмо, <br/>как только заявка будет рассмотрена. </p>
    </div>
    <div class="text-center" v-else-if="mspForsageOrder.status === 'approved'">
      <img src="/pic/accelerator/success.svg" />
      <h3 class="mt-2">Поздравляем!</h3>
      <p>Ваша компания соответствует требованиям для участия в программе.<br/> Мы отправим на Вашу электронную почту уведомление, когда Ваша компания будет включена в следующий учебный поток.</p>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MspForsageStatus",
  computed: {
    ...mapGetters(['mspForsageOrder'])
  },
  methods: {
    ...mapActions(['updateMspForsageOrder']),
    decline() {
      $('#decline').modal('show')
    },
    chooseDecline() {
      $('#decline').modal('hide')
      this.updateMspForsageOrder({status: 'failure'})
    }
  }
}
</script>

<style lang="scss">
.btn-outline-danger {
  @media (max-width: 576px) {
    display: block;
    width: 100%;
  }
}
</style>