<template>
  <div class="section__lk-content container-shadow fill-white pb-5" v-if="mspForsageOrder">
    <div class="section__lk-title">Заявка на участие в программе {{mspForsageOrder.enroll_program.name}}</div>
    <template v-if="loaded">
      <template v-if="noSend">
        <msp-forsage-menu />
        <div class="d-flex">
          <div class="section__lk-content__left">
            <div class="mt-5" v-if="mspForsageOrder.comments && mspForsageOrder.comments.length">
              <h3>Комментарии от администратора</h3>
              <admin-comments :comments="mspForsageOrder.comments"/>
            </div>
            <router-view />
          </div>
        </div>
      </template>
      <msp-forsage-status v-else />
    </template>
  </div>
</template>

<script>
import MspForsageMenu from "@/components/profile/mspForsage/MspForsageMenu.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import MspForsageStatus from "@/components/profile/mspForsage/MspForsageStatus.vue";
import mspProgramMixin from "@/mixins/mspProgramMixin";
import mspForsageOrdersMixin from "@/mixins/mspForsageOrdersMixin";
import AdminComments from "@/components/AdminComments.vue";

export default {
  name: "MspForsage",
  components: {AdminComments, MspForsageStatus, MspForsageMenu},
  mixins: [mspProgramMixin, mspForsageOrdersMixin],
  data() {
    return {
      loaded: false
    }
  },
  async mounted() {

    if(!this.mspForsageOrders.length) {
      await this.getMspForsageOrders()
    }

    this.setMspForsageOrder(
        this.mspForsageOrders.filter((order) => {
          return (order.enroll_program.id === 1 && this.programPath === 'forsage')
              || (order.enroll_program.id === 2 && this.programPath === 'msp')
        })[0]
    );

    if(this.noSend) {
      await this.getAcceleratorMembers({order: this.mspForsageOrder.id})
      await this.getCountries()
      await this.getSpecializations()
      await this.getAcceleratorProfile({order: this.mspForsageOrder.id})
    } else {
      //await this.getEnrollGroups()
    }
    this.loaded = true
  },
  methods: {
    ...mapActions(['getCountries', 'getSpecializations', 'getAcceleratorMembers', 'getEnrollGroups', 'getMspForsageOrders', 'getAcceleratorProfile']),
    ...mapMutations(['setMspForsageOrders', 'setMspForsageOrder']),
  },
  computed: {
    ...mapGetters(['mspForsageOrder', 'mspForsageOrders']),
    noSend() {
      return this.mspForsageOrder && (!this.mspForsageOrder.status || this.mspForsageOrder.status === 'draft' || this.mspForsageOrder.status === 'rejected')
    }
  },
  watch: {
    programPath: {
      handler: function (val) {
        if(this.mspForsageOrders.length) {
          this.setMspForsageOrder(
              this.mspForsageOrders.filter((order) => {
                return (order.enroll_program.id === 1 && val === 'forsage')
                    || (order.enroll_program.id === 2 && val === 'msp')
              })[0]
          );
          this.getAcceleratorMembers({order: this.mspForsageOrder.id})
          this.getAcceleratorProfile({order: this.mspForsageOrder.id})
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>