<template>
  <div class="accelerator-menu row">
    <div class="col-md-3" v-for="(menu, key) in mspMenu[programPath]" :key="key">
      <router-link tag="div" :to="menu.link" class="accelerator-menu__item p-24" :class="{success: menu.success}">
        <div class="accelerator-menu__item__circle mb-24">{{ key + 1 }}</div>
        <div>{{ menu.name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import mspProgramMixin from "@/mixins/mspProgramMixin";

export default {
  name: "MspForsageMenu",
  mixins: [mspProgramMixin],
  computed: {
    ...mapGetters(['mspMenu', 'organization', 'acceleratorMembers', 'acceleratorProfile', 'mspForsageOrder'])
  },
  methods: {
    ...mapActions(['updateMspMenu']),
    calcProfileComplete(val) {
      return val.id !== "undefined"
          && val.countries
          && val.aer_countries
          && val.field
          && val.brands
          && val.tnvd
          && val.export
          && val.share
          && val.revenue;
    }
  },
  watch: {
    organization: {
      handler: function (val) {
        if(val && val.ogrn) {
          this.updateMspMenu({route: this.programPath === 'msp' ? 'MspCompany' : 'ForsageCompany', success: true, program: this.programPath});
        }
      },
      deep: true,
      immediate: true
    },
    acceleratorMembers: {
      handler: function (val) {
        this.updateMspMenu({route: this.programPath === 'msp' ? 'MspMember' : 'ForsageMember', success: val.length > 0, program: this.programPath});
      },
      deep: true,
      immediate: true
    },
    acceleratorProfile: {
      handler: function (val) {
        if(val) {
          this.updateMspMenu({route: this.programPath === 'msp' ? 'MspProfile' : 'ForsageProfile', success: this.calcProfileComplete(val), program: this.programPath});
        }
      },
      deep: true,
      immediate: true
    },
    mspForsageOrder: {
      handler: function (val) {
        this.updateMspMenu({route: this.programPath === 'msp' ? 'MspDocs' : 'ForsageDocs', success: val.forward, program: this.programPath});
      },
      deep: true,
      immediate: true
    },
    programPath: {
      handler: function (val) {
        if(this.organization && this.organization.ogrn) {
          this.updateMspMenu({
            route: val === 'msp' ? 'MspCompany' : 'ForsageCompany',
            success: true,
            program: this.programPath
          });
        }
        if(this.acceleratorMembers) {
          this.updateMspMenu({
            route: val === 'msp' ? 'MspMember' : 'ForsageMember',
            success: this.acceleratorMembers.length > 0,
            program: this.programPath
          });
        }
        if(this.acceleratorProfile) {
          this.updateMspMenu({
            route: val === 'msp' ? 'MspProfile' : 'ForsageProfile',
            success: typeof this.acceleratorProfile.id !== "undefined",
            program: this.programPath
          });
        }
        if(this.mspForsageOrder) {
          this.updateMspMenu({
            route: this.programPath === 'msp' ? 'MspDocs' : 'ForsageDocs',
            success: typeof this.mspForsageOrder.forward !== "undefined",
            program: this.programPath
          });
        }
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss">
.accelerator-menu {
  $icons-main: url('/images/icons-main.png') no-repeat;
  &__item {
    height: 18rem;
    cursor: pointer;
    background: #FBFBFB;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem;
    &__circle {
      height: 6rem;
      line-height: 6rem;
      width: 6rem;
      color: #fff;
      text-align: center;
      border-radius: 5rem;
      background: #1C75BC;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 350;
    }
    &.active {
      background: #1C75BC;
      color: #fff;
      .accelerator-menu__item__circle {
        color: #1C75BC;
        background: #fff;
      }
    }
    &.success {
      .accelerator-menu__item__circle {
        border: #323232 3px solid;
      }
    }
  }
}
</style>